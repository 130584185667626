import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

//RadioButton と検索条件
function MultipleSearchForm({options,onSubmit,setSearchResult}) {
    const [searchType, setSearchType] = useState(options[0].value);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setSearchValue("");
        setSearchResult(null);
    };
    
    const handleSearchValueChange = (event) => {
        setSearchValue(event.target.value);
        setSearchResult(null);
    };

    const handleSearch = () => {
        onSubmit({ searchType, searchValue });
    };

    return (
    <Stack spacing={2}>
        <FormControl component="fieldset">
        <RadioGroup value={searchType} onChange={handleSearchTypeChange}>
        <Stack direction="row">
            {options.map((option) => (
            <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label} />
            ))}
        </Stack>
        </RadioGroup>
        </FormControl>
        <Stack spacing={2} direction="row">
        <TextField variant="outlined" type="search" sx={{width:300}} onChange={handleSearchValueChange} value={searchValue}/>
        <Button variant="contained" onClick={handleSearch}>検索</Button>
        </Stack>
    </Stack>
    );
}


export default MultipleSearchForm;