import React from "react";
import "./App.css";
import { Route, Routes } from 'react-router-dom';
import Dashboard from "./pages/Dashboard";
import {SearchBooking} from "./pages/Booking";
import {BookingListByPhone} from "./pages/BookingListByPhone";
import { SMSLogList } from "./pages/SMSLogList";
import { SearchUser } from "./pages/SearchUser";
import { SearchOrganization } from "./pages/SearchOrganizaiton";
import { Org } from "./pages/Org";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="searchBooking" element={<SearchBooking label="予約検索" />} />
        <Route path="searchBookingList" element={<BookingListByPhone label="予約一覧" />} />
        <Route path="SMSLogList" element={<SMSLogList label="SMS送信実績" />} />
        <Route path="searchUser" element={<SearchUser label="ユーザ検索" />} />
        <Route path="searchOrg" element={<SearchOrganization label="組織検索" />} />
        <Route path="orgDetail" element={<Org label="組織詳細" />} />
      </Routes>
  );
}

export default App;
