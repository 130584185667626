import React, { useState} from 'react';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import axios from 'axios'
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MultipleSearchForm from './MultipleSearchForm';
import { useNavigate } from 'react-router-dom';
import { API } from '../utils/Constants';

//stateには、検索条件と検索状態を持つ
//useEffectにてRendering後に検索、毎回実施しないように、初期配列は、検索結果を保持する。
//useEffectにて検索結果を保持していても、

export function OrgSearch() {
  const { user } = useAuth0();
  const [orgList, setOrgList] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableUnregDate, setDisableUnregDate] = useState(false);
  const [url, setUrl] = useState("");
  const [reason, setReason] = useState("");
  const [unregDate, setUnregDate] = useState(null);
  const [openAddAdmin, setOpenAddAdmin] = useState(false);
  const [openDelAdmin, setOpenDelAdmin] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [searchCondition, setSearchCondition] = useState(null);
  const navigate = useNavigate();

  dayjs.locale('ja')

  const defaultUnregistrationDate = () => {
    const currentDate = new Date()
    return new Date(currentDate.getTime() + (14 * 24 * 60 * 60 * 1000));
  }


  const options = [
    { value: 'org_id', label: '組織IDで検索' },
    { value: 'BCL_ID', label: 'BCL_IDで検索' },
    { value: 'name', label: '名前（部分一致）で検索' }
  ];

  const handleChangeCheckbox = v =>{
    setDisableUnregDate(v.target.checked)
  }
  const handleChangeUnregDate = v => {
    setUnregDate(v);
  };


  const showOrgDetailButton = ({row}) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            navigate('/orgDetail', { state: { org_id: row.org_id } });
          }}>
          詳細
        </Button>
      </strong>
    )
  }

  const addAdminButton = ({row}) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            setSelectedRow(row);
            setOpenAddAdmin(true);
            console.log("addAdminButton called %s",row.org_id)
          }}
          disabled={row.BCL_ADMIN != null}
        >
          追加
        </Button>
        <Dialog open={openAddAdmin} onClose={closeDialog}>
          <DialogTitle>BCLADMINへの登録確認</DialogTitle>
          <DialogContent>
            <DialogContentText>
              選択した[{selectedRow.org_name}]が、BCLADMINに登録されます。理由、チケットURLを記載して登録ボタンを押下してください。
              <br /><font color="red">{errorMessage}</font>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              label="理由"
              type="text"
              fullWidth
              variant="standard"
              onChange={reasonChange}
            />
            <TextField
              margin="dense"
              id="ticket_url"
              label="チケットURL"
              type="text"
              fullWidth
              variant="standard"
              onChange={urlChange}
            /><br /><br /><br />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <DatePicker label="最終利用日" value={dayjs(unregDate)} disabled={disableUnregDate} onChange={handleChangeUnregDate} />
          </LocalizationProvider>
          <br /><br />
          <FormControlLabel control={<Checkbox onChange={handleChangeCheckbox} />} label="恒久的に利用" />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>キャンセル</Button>
            <Button onClick={addBCLAdmin}>登録</Button>
          </DialogActions>
        </Dialog>
      </strong>
    )
  }

  const closeDialog = () => {
    setOpenAddAdmin(false);
    setOpenDelAdmin(false);
    setErrorMessage("");
  };

  const delAdminButton = ({row}) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            setSelectedRow(row);
            setOpenDelAdmin(true);
            console.log("delAdminButton called %s",row.org_id)
          }}
          disabled={row.BCL_ADMIN == null}
        >
          削除
        </Button>
        <Dialog open={openDelAdmin} onClose={closeDialog}>
          <DialogTitle>BCLADMINからの削除確認</DialogTitle>
          <DialogContent>
            <DialogContentText>
              選択した[{selectedRow.org_name}]のBCLADMINが削除されます。理由、チケットURLを記載して削除ボタンを押下してください。
              <br /><font color="red">{errorMessage}</font>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              label="理由"
              type="text"
              fullWidth
              variant="standard"
              onChange={reasonChange}
            />
            <TextField
              margin="dense"
              id="ticket_url"
              label="チケットURL"
              type="text"
              fullWidth
              variant="standard"
              onChange={urlChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>キャンセル</Button>
            <Button onClick={delBCLAdmin}>削除</Button>
          </DialogActions>
        </Dialog>
      </strong>
    )
  }

  const columns = [
    {
      field: 'org_detail',
      headerName: '詳細',
      width: 100,
      renderCell: showOrgDetailButton,
      disableClickEventBubbling: true,
    },
    {
      field: 'addAdmin',
      headerName: 'admin',
      width: 80,
      renderCell: addAdminButton,
      disableClickEventBubbling: true,
    },
    {
      field: 'delAdmin',
      headerName: 'admin',
      width: 80,
      renderCell: delAdminButton,
      disableClickEventBubbling: true,
    },
    {
      field: 'org_id',
      headerName: '組織ID',
      width: 220,
    },
    {
      field: 'org_name',
      headerName: '組織名',
      width: 200,
    },
    {
      field: 'phone',
      headerName: 'OWNER_BCL_ID',
      width: 180,
    },
    {
      field: 'airtable_client_id',
      headerName: 'AIRTABLE_ID',
      width: 150,
    },
    {
      field: 'stripe_email',
      headerName: 'email(Stripe)',
      width: 250,
    },
    {
      field: 'stripe_customer_id',
      headerName: 'customerID(Stripe)',
      width: 200,
    },
    {
      field: 'service_units',
      headerName: 'LaaP契約数',
      width: 120,
    },
    {
      field: 'used_units',
      headerName: 'LaaP利用数',
      width: 120,
    },
    {
      field: 'device_count',
      headerName: 'デバイス数',
      width: 120,
    },
    {
      field: 'web_url',
      headerName: 'web_url',
      width: 320,
    },
    {
      field: 'org_category',
      headerName: 'カテゴリ',
      width: 80,
    },
    {
      field: 'unreg_flag',
      headerName: '解約フラグ',
      width: 120,
    },
    {
      field: 'del_flag',
      headerName: '削除フラグ',
      width: 120,
    },
  ];


  const urlChange = event => {
    setUrl(event.target.value);
  };

  const reasonChange = event => {
    setReason(event.target.value);
  };

  const addBCLAdmin = () => {
    const postdata = {
      "org_id": selectedRow.org_id,
      "operator": user.name,
      "reason": reason,
      "url": url,
      "target_date": disableUnregDate?null:unregDate,
    }
    console.log("called addBCLAdmin %s",JSON.stringify(postdata))
    axios.post(API.ENDPOINT + '/v1/org/member/add'
      , postdata,
      { headers: { 'Content-Type': 'text/plain', 'x-api-key': API.X_API_KEY }, responseType: 'json' })
      .then(res => {
        console.log(res.data)
        closeDialog()
        //再検索
        handleSearch(searchCondition);
      }).catch(error => {
        setErrorMessage(error.response.data["error_message"])
      });
  }

  const delBCLAdmin = () => {
    const postdata = {
      "org_id": selectedRow.org_id,
      "operator": user.name,
      "reason": reason,
      "url": url,
    }
    console.log("called dellBCLAdmin %s",JSON.stringify(postdata))
    axios.post(API.ENDPOINT + '/v1/org/member/del'
      , postdata,
      { headers: { 'Content-Type': 'text/plain', 'x-api-key': API.X_API_KEY }, responseType: 'json' })
      .then(res => {
        console.log(res.data)
        closeDialog()
        //再検索
        handleSearch(searchCondition);
      }).catch(error => {
        setErrorMessage(error.response.data["error_message"])
      });
  }


  /** 検索処理*/
  const handleSearch = (formData) => {
    console.log("Search Type:", formData.searchType);
    console.log("Search Value:", formData.searchValue);
    setSearchCondition(formData)

    switch(formData.searchType){
      case 'name':
        searchByOrgName(formData.searchValue)
        break
      case 'org_id':
        searchByOrgId(formData.searchValue)
        break
      case 'BCL_ID':
        searchByOwnerOfBCLID(formData.searchValue)
        break
      default:
        break
    }
  }

  const searchByOrgName = (orgName) => {
    axios.get(API.ENDPOINT + '/v1/org/search?org_name=' + orgName,
      { headers: { 'Content-Type': 'text/plain', 'x-api-key': API.X_API_KEY }, responseType: 'json' })
      .then(res => {
        console.log(res.data)
        setOrgList(res.data)
        setUnregDate(defaultUnregistrationDate())
        setDisableUnregDate(false);
      }).catch(error => {
        setOrgList([]);
        setErrorMessage(error.response.data["error_message"])
      });
  }

  const searchByOwnerOfBCLID = (phone) => {
    axios.get(API.ENDPOINT + '/v1/org/bclid/' + phone,
      { headers: { 'Content-Type': 'text/plain', 'x-api-key': API.X_API_KEY }, responseType: 'json' })
      .then(res => {
        console.log(res.data)
        setOrgList(res.data)
        setUnregDate(defaultUnregistrationDate())
        setDisableUnregDate(false);
      }).catch(error => {
        setOrgList([]);
        setErrorMessage(error.response.data["error_message"])
      });
  }

  const searchByOrgId = (org_id) => {
    axios.get(API.ENDPOINT + '/v1/org/id/' + org_id,
      { headers: { 'Content-Type': 'text/plain', 'x-api-key': API.X_API_KEY }, responseType: 'json' })
      .then(res => {
        console.log(res.data)
        const orgList =[]
        orgList.push(res.data)
        setOrgList(orgList)
        setUnregDate(defaultUnregistrationDate())
        setDisableUnregDate(false);
      }).catch(error => {
        setOrgList([]);
        setErrorMessage(error.response.data["error_message"])
      });
  }


  return (
    <div style={{ height: 650, width: '100%' }}>
      <Stack spacing={2} direction="row">
        <MultipleSearchForm options={options} onSubmit={handleSearch} setSearchResult={setOrgList}/>
      </Stack>
      <br />
      {orgList && <DataGrid
        getRowId={(row) => row.org_id}
        rows={orgList}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[1]}
        disableSelectionOnClick
      />}
    </div>
  );

}