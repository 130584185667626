export const API = {
    ENDPOINT: process.env.REACT_APP_ENDPOINT,
    X_API_KEY: process.env.REACT_APP_X_API_KEY
};

export const AUTH0 = {
    CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN
};

export const isValidUrl = (v) => {
    try {
      new URL(v);
      return true;
    } catch (err) {
      console.log(err)
      return false;
    }
  }

export const isValidEmail = (v) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(v);
  }

export const formatDateToYYYYMMDD = (v) => {
  const date = new Date(v)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export  const POWER_USER = ["hiroshi.abe@blockchainlock.io","ken.okamoto@blockchainlock.io","satoko.yoshizawa@blockchainlock.io","yoko.sayama@blockchainlock.io"]
