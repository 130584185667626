import React ,{ useState,useEffect } from 'react';

import axios from 'axios'
import { API } from '../utils/Constants';
import { DataGrid } from '@mui/x-data-grid';
import  SingleSearchForm from './SingleSearchForm';

//stateには、検索条件と検索状態を持つ
//useEffectにてRendering後に検索、毎回実施しないように、初期配列は、検索結果を保持する。
//useEffectにて検索結果を保持していても、


const columns = [
  {
    field: '予約番号',
    headerName: '予約番号',
    width: 150,
  },
  {
    field: '組織ID',
    headerName: '組織ID',
    width: 150,
  },
  {
    field: '組織名',
    headerName: '組織名',
    width: 110,
  },
  {
    field: '場所名',
    headerName: '場所',
    width: 160,
  },
  {
    field: 'プラン',
    headerName: 'プラン',
    width: 160,
  },
  {
    field: '部屋番号',
    headerName: '部屋番号',
    width: 160,
  },
  {
    field: 'チェックイン',
    headerName: 'チェックイン',
    sortable: false,
    width: 160,
  },
  {
    field: 'チェックアウト',
    headerName: 'チェックアウト',
    sortable: false,
    width: 160,
  },
  {
    field: '予約作成日時',
    headerName: '予約作成日時',
    sortable: false,
    width: 160,
  },
];

export  function BookingList(props) {
  const [bookingList, setBookingList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleChange = event => {
    setSearchKeyword(event.target.value);
  };

  const handleClick = event => {
    axios.get(API.ENDPOINT+'/v1/booking/member/'+searchKeyword,
    { headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
    .then(res => {
        setBookingList(res.data)
    }).catch(err => {
      console.log(err.response.data)})
      setBookingList([])
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <SingleSearchForm id='bcl_id' label='BCLID(電話番号)' placeholder='09012345678' handleClick={handleClick} handleChange={handleChange}/>
      <DataGrid
        getRowId={(row) => row.予約番号}
        rows={bookingList}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}