import React from 'react';
import BasicMenu from '../components/Menu';
import { Grid } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { OrgSearch } from '../components/OrgSearch';

export function SearchOrganization() {
  const {isAuthenticated, isLoading,loginWithRedirect } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if(!isAuthenticated){
    loginWithRedirect()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <BasicMenu/>
      </Grid>
      <Grid item xs={1}>
      </Grid>
      <Grid item xs={10}>
        <OrgSearch/>
      </Grid>
    </Grid>
  );
}
