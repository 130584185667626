import { Divider, List, ListItem, ListItemText } from '@mui/material';

export default function UserDetail(props) {
    const style = {
        py: 0,
        width: '100%',
        maxWidth: 500,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
    };

    const moment = require('moment');
    const UTC_TIME = props.user_created_at;
    const JST_TIME = moment.utc(UTC_TIME).local().format('YYYY/MM/DD HH:mm:ss');

return<List sx={style}>
      <ListItem>
        <ListItemText primary="BCL_ID:" sx={{ minWidth: 200 }} align="left"/>
        <ListItemText primary={props.bcl_id} sx={{ minWidth: 200 }}  align="left"/>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText primary="ユーザ名:" sx={{ minWidth: 200 }} align="left"/>
        <ListItemText primary={props.user_name} sx={{ minWidth: 200 }} align="left"/>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText primary={"メールアドレス : "} sx={{ minWidth: 200 }} align="left"/>
        <ListItemText primary={props.user_email} sx={{ minWidth: 200 }} align="left"/>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText primary={"social : "} sx={{ minWidth: 200 }} align="left"/>
        <ListItemText primary={props.appid} sx={{ minWidth: 200 }} align="left"/>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText primary={"uid : "} sx={{ minWidth: 200 }} align="left"/>
        <ListItemText primary={props.uid} sx={{ minWidth: 200 }} align="left"/>
      </ListItem>
      <Divider component="li" />
      <ListItem>
        <ListItemText primary={"ユーザ登録日時 : "} sx={{ minWidth: 200 }} align="left"/>
        <ListItemText primary={JST_TIME} sx={{ minWidth: 200 }} align="left"/>
      </ListItem>
    </List>
}


