import React ,{ useState } from 'react';
import Stack from '@mui/material/Stack';
import axios from 'axios'
import { API} from '../utils/Constants';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import  MultipleSearchForm from './MultipleSearchForm';
import UserDetail from './UserDetail';
import { useNavigate } from 'react-router-dom';

export function UserSearch() {
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const options = [
    { value: 'BCL_ID', label: 'BCL_IDで検索' },
    { value: 'email', label: 'emailで検索' }
  ];
  

  const showOrgDetailButton = ({row}) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            navigate('/orgDetail', { state: { org_id: row.org_id } });
          }}>
          詳細
        </Button>
      </strong>
    )
  }

  const columns = [
    {
      field: 'org_id',
      headerName: '組織ID',
      width: 220,
    },
    {
      field: 'org_name',
      headerName: '組織名',
      width: 400,
    },
    {
      field: 'role_name',
      headerName: 'ロール',
      width: 200,
    },
    {
      field: 'org_detail',
      headerName: '詳細',
      width: 100,
      renderCell: showOrgDetailButton,
      disableClickEventBubbling: true,
    },

  ];



  /** 検索処理*/
  const handleSearch = (formData) => {
    console.log("Search Type:", formData.searchType);
    console.log("Search Value:", formData.searchValue);

    switch(formData.searchType){
      case 'email':
        searchByEmail(formData.searchValue)
        break
      case 'BCL_ID':
        searchByBclId(formData.searchValue)
        break
      default:
        break
    }
  }

  const searchByBclId = (bclid) => {
    axios.get(API.ENDPOINT + '/v1/user/bclid/' + bclid,
      { headers: { 'Content-Type': 'text/plain', 'x-api-key': API.X_API_KEY }, responseType: 'json' })
      .then(res => {
        console.log(res.data)
        setUser(res.data)
      }).catch(error => {
        setUser(null);
        setErrorMessage(error.response.data["error_message"])
      });
  }


  const searchByEmail = (email) => {
    axios.get(API.ENDPOINT + '/v1/user/email/' + email,
      { headers: { 'Content-Type': 'text/plain', 'x-api-key': API.X_API_KEY }, responseType: 'json' })
      .then(res => {
        console.log(res.data)
        setUser(res.data)
      }).catch(error => {
        setUser(null);
        setErrorMessage(error.response.data["error_message"])
      });
  }


  return (
    <div style={{ height: 650, width: '100%' }}>
      <Stack spacing={2} direction="row">
        <MultipleSearchForm options={options} onSubmit={handleSearch} setSearchResult={setUser}/>
      </Stack>
      <br />

      { user &&
      <div><br/>
      <UserDetail bcl_id={user.phone} user_name={user.name} user_email={user.email} appid={user.appid} uid={user.uid} user_created_at={user.user_created_at} />
      </div>}
      {user && user.orgList && 
      <div>
      <br/>
      <DataGrid 
        sx={{ height: 350 }}
        getRowId={(row) => row.org_id}
        rows={user.orgList}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      /></div>
    }
    </div>
  );
}